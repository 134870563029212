<template>
  <div class="w-full px-0 py-2">
    <div v-if="complaint" class="w-full">
      <h2 class="mb-5 text-md text-left text-primary">
        <b>Datos de contacto</b>
      </h2>
      <BaseRadio
        name="person-type"
        id="person-type"
        label="Tipo de persona"
        :radioOptions="formOptions.optionsPersonType"
        :value="complaint.tipo_persona"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Nombres"
        :value="complaint.nombres"
        :disabled="true"
      />
      <BaseSelect
        label="Tipo de identificación"
        id="identification"
        :value="complaint.tipo_id_CF"
        :selectOptions="formOptions.optionsIdentificationType"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Número de identificación"
        placeholder="Ej:123456789"
        :value="complaint.numero_id_CF"
        :disabled="true"
      />
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-4" v-if="complaint.sexo">
      <BaseSelect
        id="sexo"
        label="Sexo"
        :selectOptions="formOptions.optionsGender"
        :value="complaint.sexo"
        :disabled="true"
      />
      <BaseRadio
        name="LGTBIQ+"
        label="LGTBIQ+"
        :radioOptions="formOptions.optionsYN"
        :value="complaint.tipo_persona"
        :disabled="true"
      />
    </div>
    <div class="w-full">
      <BaseInput
        v-if="complaint.condicion_especial !== 98"
        id="special-condition"
        type="text"
        label="Condición especial"
        :value="complaint.condicion_especial_valor"
        :disabled="true"
      />
    </div>
    <div v-if="complaint" class="w-full">
      <h2 class="my-5 text-md text-left text-primary">
        <b>Detalle de la queja</b>
      </h2>
      <BaseInput
        v-if="complaint"
        type="text"
        id="abroad"
        label="Inconformidad que motiva su queja ocurrió el exterior"
        :value="abroad"
        :disabled="true"
      />
      <div v-if="abroad == 'Si'">
        <BaseInput
          v-if="complaint"
          type="text"
          id="country"
          label="País donde ocurrio la incidencia"
          :value="complaint.pais_valor"
          :disabled="true"
        />
      </div>
      <div v-if="abroad == 'No'">
        <BaseInput
          v-if="complaint"
          type="text"
          id="department"
          label="Departamento donde ocurrio la incidencia"
          :value="complaint.departamento_valor"
          :disabled="true"
        />
        <BaseInput
          v-if="complaint"
          type="text"
          id="city"
          label="Municipio donde ocurrio la incidencia"
          :value="complaint.municipio_valor"
          :disabled="true"
        />
      </div>
      <BaseInput
        v-else
        type="text"
        label="País"
        :value="complaint.pais_valor"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="La inconformidad está relacionada con alguno de los siguientes productos"
        :value="complaint.producto_valor"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Motivo de su inconformidad"
        :value="complaint.motivo_valor"
        :disabled="true"
      />
      <BaseTextarea
        label="Detalle del producto"
        placeholder="Descripción"
        :value="complaint.producto_nombre"
        :disabled="true"
        maxlength="100"
      />
      <BaseInput
        type="text"
        label="Canal"
        :value="complaint.canal_valor"
        :disabled="true"
      />
      <BaseTextarea
        ref="describe"
        id="describe"
        label="Describa los hechos brevemente:"
        placeholder="Mensaje"
        :value="complaint.texto_queja"
        required="true"
        :disabled="true"
        maxlength="1000"
      />
      <div v-if="complaint.files && complaint.files.length > 0" class="mt-4">
        <p class="mb-3 text-xs text-left font-bold text-gray-500">Anexos</p>
        <BaseDownload
          v-for="file in complaint.files"
          :value="file.filename"
          :key="file.filename"
          :url_download="file.storage_full_path"
        />
      </div>
      <BaseInput
        v-if="complaint.aceptacion_queja_valor"
        label="Aceptación"
        :value="complaint.aceptacion_queja_valor"
        disabled
      />
      <BaseInput
        v-if="complaint.rectificacion_queja_valor"
        label="Rectificación"
        :value="complaint.rectificacion_queja_valor"
        disabled
      />
      <BaseInput
        v-if="complaint.desistimiento_queja_valor"
        label="Desistimiento"
        :value="complaint.desistimiento_queja_valor"
        disabled
      />
      <BaseInput
        v-if="complaint.admision_valor"
        label="Admisión"
        :value="complaint.admision_valor"
        disabled
      />
      <BaseInput
        v-if="complaint.tutela_valor"
        label="Tutela"
        :value="complaint.tutela_valor"
        disabled
      />
      <BaseInput
        v-if="complaint.queja_expres_valor"
        label="Queja express"
        :value="complaint.queja_expres_valor"
        disabled
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['complaint'],
  computed: {
    abroad: function () {
      if (this.complaint.codigo_pais != '170') {
        return 'Si'
      } else {
        return 'No'
      }
    },
    ...mapState({ formOptions: 'form' }),
  },
}
</script>

<style></style>
