<template>
  <div v-if="history.length === 0">
    <p class="font-bold">No hay registros</p>
  </div>
  <div v-else>
    <a-collapse
      v-model="activeKey"
      expand-icon-position="right"
      :accordion="true"
      :bordered="false"
      class="border-0"
    >
      <a-collapse-panel
        v-for="(instance, index) in history"
        :key="index"
        class="m-3 text-left bg-white text-white mb-3 border-b-2 overflow-hidden shadow border-0"
        :style="{ border: '0' }"
      >
        <template #header>
          <span
            >{{ instance.accion }} -
            {{
              $DateTime.fromISO(instance.fecha).toFormat('dd/L/yyyy HH:mm:ss')
            }}</span
          >
        </template>

        <div>
          <HistoryComplaintDetail :complaint="instance.queja" />
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import HistoryComplaintDetail from '@/components/Dashboard/Complaints/Complaint/HistoryComplaintDetail'
export default {
  components: {
    HistoryComplaintDetail,
  },
  data() {
    return {
      loading: false,
      history: [],
      activeKey: ['1'],
    }
  },
  async mounted() {
    this.loading = true
    const { complaint_id } = this.$route.params
    const response = await this.$api.complaints.getComplaintHistory(
      complaint_id
    )
    this.loading = false
    if (response.status !== 200) return
    this.history = response.data
  },
}
</script>

<style></style>
